<template>
    <div class="content">
        <p>iOS技术支持</p>
        <p>有问题的可以留言。</p>
        <p>邮箱地址：w7n3j1@sina.com</p>
        <p>谢谢!</p>
        <p>iOS program design & system consultation</p>
        <p>if you have any question, please contact me with no hesitate</p>
        <p>Email: w7n3j1@sina.com</p>
        <p>Thank you!</p>
    </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
.content {
    padding: 10px;
}
p {
    font-size: 14px;
}
</style>
